<template>
  <v-card>
    <v-card-title class="accent">
      <v-icon class="mr-2">mdi-checkbox-marked-outline</v-icon>
      Usuários Checkin
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            text
            @click="reloadGet"
            class="mr-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>Recarregar</span>
      </v-tooltip>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text class="d-flex align-center justify-center" v-if="graficoData.length">
      <Pie
        :options="options"
        :chartData="graficoUsuarios"
        :styles="styles"
      />
    </v-card-text>
    <v-card-text
      v-else
      class="py-6 text-subtitle-1 d-flex align-center justify-center"
    >
      Nenhum Usuário Fez Checkin
    </v-card-text>
  </v-card>
</template>

<script>
import { userCheckin } from "@/api/admin/graficos/usuarios.js";
export default {
  name: "BoxUsuariosCheckin",

  components: {
    Pie: () => import("@/views/home/graficos/Pie.vue"),
  },

  data() {
    return {
      graficoData: [],
      graficoLabel: [],
      graficoColor: [],
      loading: false,
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
      styles: {
        width: "300px",
        borderAlign: "center",
      },
    };
  },

  computed: {
    graficoUsuarios() {
      return {
        labels: this.graficoLabel,
        datasets: [
          {
            data: this.graficoData,
            backgroundColor: this.graficoColor,
          },
        ],
      };
    },
    totalUsuarios() {
      let result = 0;
      if (this.graficoData.length) {
        result = this.graficoData.reduce(
          (accumulator, dado) => accumulator + dado,
          0
        );
      }
      return result;
    },
  },

  methods: {
    getUserCheckin() {
      this.loading = true;
      userCheckin()
        .then((response) => {
          response.forEach((item) => {
            if (item.value) {
              this.graficoData.push(item.qtde);
              this.graficoLabel.push("Fez Checkin");
              this.graficoColor.push("#A5D6A7");
            } else {
              this.graficoData.push(item.qtde);
              this.graficoLabel.push("Não Fez Checkin");
              this.graficoColor.push("#EF9A9A");
            }
          });
          // this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    reloadGet() {
      this.graficoData = [];
      this.graficoLabel = [];
      this.graficoColor = [];
      this.getUserCheckin();
    },
  },

  mounted() {
    this.getUserCheckin();
  },
};
</script>

<style></style>
